import React, {
  ChangeEvent,
  ComponentType,
  PureComponent,
  ReactNode,
} from 'react';

import keycode from 'keycode';
import memoize from 'memoize-one';
import { injectIntl, InjectedIntl } from 'react-intl';
import Loadable from 'react-loadable';

import { NavigationSkeleton } from '@atlaskit/atlassian-navigation/skeleton';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import {
  LayoutManager,
  NavigationProvider,
  ThemeProvider,
  UIControllerSubscriber,
  UIControllerInterface,
  Mode as ThemeMode,
  // @ts-ignore TODO: fix noImplicitAny error here
} from '@atlaskit/navigation-next';
import { LeftSidebarState } from '@atlaskit/page-layout';

import { Repository, Workspace } from 'src/components/types';

import { ResizeEvent, MenuItem, OperationalEvent, UiEvent } from '../types';

import { AccountMenuUserProps } from './account-menu';
import { ContainerNavigationProps } from './container-navigation-next';
import DrawerHeader from './drawer-header';
import messages from './index-next.i18n';
import { light, settings, siteSettings } from './theme-modes';

type GlobalNavigationListenerProps = {
  onCloneClick: () => void;
  onCreateDrawerClose: () => void;
  /* eslint react/no-unused-prop-types: "warn" */
  onCreateDrawerOpen: () => void;
  onKeyboardShortcutsActivated: () => void;
  onFeedbackModalOpen: () => void;
  onProductClick?: () => void;
  onSearch?: (event: ChangeEvent) => void;
  onSearchDrawerClose: () => void;
  onSearchDrawerInit?: () => void;
  onSearchDrawerOpen?: () => void;
  onSearchSubmit?: () => void;
  onToggleAtlassianSwitcher: (isOpen: boolean) => void;
  publishScreenEvent: (name: string, attributes?: object) => void;
  publishTrackEvent: (event: OperationalEvent) => void;
  publishUiEvent: (event: UiEvent) => void;
};

export type SearchTargetUserProps = {
  targetUserUuid?: string;
  targetUserDisplayName?: string;
};

type GlobalNavigationStateProps = AccountMenuUserProps &
  SearchTargetUserProps & {
    containerBitbucketActions?: MenuItem[];
    containerConnectActions?: MenuItem[];
    currentProject?: BB.Project;
    repoName?: string;
    repoSlug?: string;
    drawerHeader: ReactNode;
    globalBitbucketActions: MenuItem[];
    themeMode: ThemeMode;
    hasPride: boolean;
    isAccountSettingsIndexEnabled: boolean;
    isAtlassianSwitcherOpen: boolean;
    isCreateDrawerOpen: boolean;
    isNavigationOpen: boolean;
    isSearchDrawerOpen: boolean;
    linkComponent?: ComponentType<any>;
    pullRequestLinkComponent?: ComponentType;
    repositoryLinkComponent?: ComponentType;
    searchQuery?: string;
    recentlyViewedWorkspacesKeys?: string[];
    adminHubUrl?: string;
    attributionsUrl?: string;
    whatsNewUrl?: string;
    handleProductClick?: (e: React.MouseEvent) => void;
    isWorkspaceAdmin?: boolean;
    isNavThemeSelectorEnabled?: boolean;
  };

const COLLAPSE_TOGGLE_SHORTCUT = '[';
const OPEN_STATE = {
  EXPANDED: 'expanded',
  COLLAPSED: 'collapsed',
};

type InjectedProps = {
  intl: InjectedIntl;
};

type PassThroughProps = Omit<
  GlobalNavigationStateProps,
  'drawerHeader' | 'themeMode' | 'isNavigationOpen'
>;

const HorizontalNav = Loadable({
  loading: () => (
    <NavigationSkeleton
      primaryItemsCount={5}
      secondaryItemsCount={2}
      shouldShowSearch
    />
  ),
  loader: () =>
    import(
      /* webpackChunkName: "horizontal-navigation" */ './horizontal-nav/horizontal-navigation'
    ),
});

const MobileNav = Loadable({
  loading: () => null,
  loader: () =>
    import(
      /* webpackChunkName: "horizontal-mobile-nav" */ './horizontal-nav/mobile-navigation/horizontal-mobile-nav'
    ),
});

export type NavigationNextListenerProps = GlobalNavigationListenerProps & {
  onResize: (event: ResizeEvent) => void;
  publishOperationalEvent: (event: OperationalEvent) => void;
};

export type NavigationNextStateProps = PassThroughProps & {
  children?: ReactNode;
  containerType?: string;
  containerHref?: string;
  containerLogo?: string;
  containerName?: string;
  containerNavigationItems?: MenuItem[];
  selectedContainerNavigationItem?: MenuItem;
  createDrawerHeaderLinkComponent?: ComponentType<any>;
  currentProject?: BB.Project;
  currentWorkspace?: Workspace;
  currentRepository?: Repository;
  defaultNavigationOpen: boolean;
  environment: string;
  hasSettingsNavTheme?: boolean;
  hasSiteSettingsNavTheme?: boolean;
  horizontalNavigationItems?: {
    mainItems: MenuItem[];
    secondaryItems: MenuItem[];
    settingsItems: MenuItem[];
  };
  isBeingRenderedInsideMobileNav: boolean;
  isCreateDrawerOpen: boolean;
  isGlobalContext: boolean;
  isMobileHeaderActive: boolean;
  isPrivate?: boolean;
  isSearchDrawerOpen: boolean;
  navigationType?: 'container' | 'product';
  recentlyViewed?: BB.Repository[];
  containerNavigationComponent?: ComponentType;
  useContainerNavigationProps?: () => ContainerNavigationProps;
  topOffset?: number;
};

export type AppDataProps = {
  tenantId?: string;
};

// These props are currently shared between the navigation-next implementation
// and the v3 implementation
type SharedNavigationProps = NavigationNextListenerProps &
  NavigationNextStateProps &
  AppDataProps;

type NavigationNextProps = SharedNavigationProps & InjectedProps;

export type NavigationV3Props = SharedNavigationProps & {
  useContainerNavigationProps: () => ContainerNavigationProps;
  onLeftSidebarExpand?: (state: LeftSidebarState) => void;
  onLeftSidebarCollapse?: (state: LeftSidebarState) => void;
  onResizeStart?: (state: LeftSidebarState) => void;
  onResizeEnd?: (state: LeftSidebarState) => void;
};

type State = Partial<{
  isCollapsed: boolean;
  collapseButtonRef: HTMLElement | undefined;
}>;

const buildUiController = memoize(
  (isMobileHeaderActive: boolean, isInitiallyCollapsed: boolean) => ({
    isResizeDisabled: isMobileHeaderActive,
    isCollapsed: isInitiallyCollapsed,
  })
);

export class NavigationNext extends PureComponent<NavigationNextProps, State> {
  static defaultProps = {
    /* eslint react/default-props-match-prop-types: "warn" */
    hasPride: false,
    hasSettingsNavTheme: false,
    hasSiteSettingsNavTheme: false,
    containerNavigationComponent: () => <div />,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleGlobalKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleGlobalKeyDown);
  }

  // We want to hide the "global" blue navigation if any of these cases is true:
  // a. we are in mobile nav mode AND this instance of the nav is NOT being rendered inside the mobile nav
  // b. horizontal nav is enabled and we're on the dashboard
  isGlobalNavHidden = () => {
    const { isMobileHeaderActive, isBeingRenderedInsideMobileNav } = this.props;
    return isMobileHeaderActive && !isBeingRenderedInsideMobileNav;
  };

  renderGlobalNavigation = () => {
    const showMobileNav: boolean = this.shouldRenderGlobalNavMobile();
    const showHNav: boolean = this.shouldRenderGlobalNavHorizontal();

    if (!showHNav && !showMobileNav) {
      return null;
    }

    const {
      containerBitbucketActions,
      containerConnectActions,
      containerHref,
      containerLogo,
      containerName,
      createDrawerHeaderLinkComponent,
      currentProject,
      globalBitbucketActions,
      horizontalNavigationItems,
      isGlobalContext,
      isPrivate,
      linkComponent,
      switchAccountUrl,
      manageAccountUrl,
      loginUrl,
      logoutUrl,
      onCloneClick,
      onCreateDrawerClose,
      onSearch,
      onSearchDrawerInit,
      onSearchSubmit,
      pullRequestLinkComponent,
      recentlyViewed,
      recentlyViewedWorkspacesKeys,
      repoName,
      repositoryLinkComponent,
      repoSlug,
      searchQuery,
      userAvatarUrl,
      userEmail,
      userDisplayName,
      userUuid,
      userWorkspaceId,
      onKeyboardShortcutsActivated,
      onFeedbackModalOpen,
      adminHubUrl,
      attributionsUrl,
      whatsNewUrl,
      onToggleAtlassianSwitcher,
      isAtlassianSwitcherOpen,
      isOrgAdmin,
      isWorkspaceAdmin,
      workspaceOrgId,
      publishUiEvent,
      useContainerNavigationProps,
      isSearchDrawerOpen,
      onSearchDrawerClose,
      onSearchDrawerOpen,
      workspaceLinkComponent,
      hasPride,
      currentRepository,
      currentWorkspace,
      currentUser,
      environment,
      tenantId,
      isNavThemeSelectorEnabled,
    } = this.props;

    const drawerHeader = (
      <DrawerHeader
        isGlobalContext={isGlobalContext}
        isPrivate={isPrivate}
        href={containerHref}
        name={containerName}
        logo={containerLogo}
        linkComponent={createDrawerHeaderLinkComponent}
      />
    );

    if (showMobileNav) {
      return (
        <MobileNav
          avatarUrl={userAvatarUrl}
          environment={environment}
          containerBitbucketActions={containerBitbucketActions}
          containerConnectActions={containerConnectActions}
          currentProject={currentProject}
          drawerHeader={drawerHeader}
          globalBitbucketActions={globalBitbucketActions}
          switchAccountUrl={switchAccountUrl}
          manageAccountUrl={manageAccountUrl}
          loginUrl={loginUrl}
          logoutUrl={logoutUrl}
          menuItems={
            horizontalNavigationItems || {
              mainItems: [],
              secondaryItems: [],
              settingsItems: [],
            }
          }
          isLoggedIn={Boolean(userUuid)}
          linkComponent={linkComponent}
          onCloneClick={onCloneClick}
          onCreateDrawerClose={onCreateDrawerClose}
          onSearch={onSearch}
          onSearchDrawerInit={onSearchDrawerInit}
          onSearchSubmit={onSearchSubmit}
          isSearchDrawerOpen={isSearchDrawerOpen}
          onSearchDrawerClose={onSearchDrawerClose}
          onSearchDrawerOpen={onSearchDrawerOpen}
          pullRequestLinkComponent={pullRequestLinkComponent}
          recentlyViewed={recentlyViewed}
          recentlyViewedWorkspacesKeys={recentlyViewedWorkspacesKeys}
          repoName={repoName}
          repositoryLinkComponent={repositoryLinkComponent}
          repoSlug={repoSlug}
          searchQuery={searchQuery}
          userAvatarUrl={userAvatarUrl}
          userEmail={userEmail}
          userDisplayName={userDisplayName}
          userUuid={userUuid}
          userWorkspaceId={userWorkspaceId}
          onKeyboardShortcutsActivated={onKeyboardShortcutsActivated}
          onFeedbackModalOpen={onFeedbackModalOpen}
          adminHubUrl={adminHubUrl}
          attributionsUrl={attributionsUrl}
          whatsNewUrl={whatsNewUrl}
          onToggleAtlassianSwitcher={onToggleAtlassianSwitcher}
          isAtlassianSwitcherOpen={isAtlassianSwitcherOpen}
          isOrgAdmin={isOrgAdmin}
          isWorkspaceAdmin={isWorkspaceAdmin}
          workspaceOrgId={workspaceOrgId}
          publishUiEvent={publishUiEvent}
          useContainerNavigationProps={useContainerNavigationProps}
          containerHref={containerHref}
          containerName={containerName}
          handleProductClick={this.handleProductClick}
          workspaceLinkComponent={workspaceLinkComponent}
          currentRepository={currentRepository}
          currentWorkspace={currentWorkspace}
          currentUser={currentUser}
          isNavThemeSelectorEnabled={isNavThemeSelectorEnabled}
        />
      );
    } else {
      return (
        <HorizontalNav
          hasPride={hasPride}
          avatarUrl={userAvatarUrl}
          environment={environment}
          containerBitbucketActions={containerBitbucketActions}
          containerConnectActions={containerConnectActions}
          currentProject={currentProject}
          drawerHeader={drawerHeader}
          globalBitbucketActions={globalBitbucketActions}
          switchAccountUrl={switchAccountUrl}
          manageAccountUrl={manageAccountUrl}
          loginUrl={loginUrl}
          logoutUrl={logoutUrl}
          menuItems={
            horizontalNavigationItems || {
              mainItems: [],
              secondaryItems: [],
              settingsItems: [],
            }
          }
          isLoggedIn={Boolean(userUuid)}
          linkComponent={linkComponent}
          onCloneClick={onCloneClick}
          onCreateDrawerClose={onCreateDrawerClose}
          onSearch={onSearch}
          onSearchDrawerInit={onSearchDrawerInit}
          isSearchDrawerOpen={isSearchDrawerOpen}
          onSearchDrawerClose={onSearchDrawerClose}
          onSearchDrawerOpen={onSearchDrawerOpen}
          onSearchSubmit={onSearchSubmit}
          pullRequestLinkComponent={pullRequestLinkComponent}
          recentlyViewed={recentlyViewed}
          recentlyViewedWorkspacesKeys={recentlyViewedWorkspacesKeys}
          repoName={repoName}
          repositoryLinkComponent={repositoryLinkComponent}
          repoSlug={repoSlug}
          searchQuery={searchQuery}
          userAvatarUrl={userAvatarUrl}
          userEmail={userEmail}
          userDisplayName={userDisplayName}
          userUuid={userUuid}
          userWorkspaceId={userWorkspaceId}
          onKeyboardShortcutsActivated={onKeyboardShortcutsActivated}
          onFeedbackModalOpen={onFeedbackModalOpen}
          adminHubUrl={adminHubUrl}
          attributionsUrl={attributionsUrl}
          whatsNewUrl={whatsNewUrl}
          onToggleAtlassianSwitcher={onToggleAtlassianSwitcher}
          isAtlassianSwitcherOpen={isAtlassianSwitcherOpen}
          isOrgAdmin={isOrgAdmin}
          isWorkspaceAdmin={isWorkspaceAdmin}
          workspaceOrgId={workspaceOrgId}
          handleProductClick={this.handleProductClick}
          publishUiEvent={publishUiEvent}
          workspaceLinkComponent={workspaceLinkComponent}
          currentRepository={currentRepository}
          currentWorkspace={currentWorkspace}
          currentUser={currentUser}
          tenantId={tenantId}
          isNavThemeSelectorEnabled={isNavThemeSelectorEnabled}
        />
      );
    }
  };

  // This is a bit of a hack since Atlaskit doesn't really work with AUI
  // see https://ecosystem.atlassian.net/browse/AK-3456
  // @ts-ignore TODO: fix noImplicitAny error here
  handleGlobalKeyDown = e => {
    const {
      isAtlassianSwitcherOpen,
      isCreateDrawerOpen,
      isSearchDrawerOpen,
      onSearchDrawerClose,
      onCreateDrawerClose,
      onToggleAtlassianSwitcher,
    } = this.props;
    if (keycode(e) === 'esc') {
      if (isCreateDrawerOpen) {
        onCreateDrawerClose();
      } else if (isSearchDrawerOpen) {
        onSearchDrawerClose();
      } else if (isAtlassianSwitcherOpen) {
        onToggleAtlassianSwitcher(false);
      }
    }
  };

  handleProductClick = (e: React.MouseEvent) => {
    const { onProductClick } = this.props;

    // Following checks are for not preventing the "open in tab" action:
    if (e.metaKey) {
      // e.metaKey is 'true' for cmd + click on Mac.
      return;
    }
    if (e.ctrlKey) {
      // e.ctrlKey is 'true' for ctrl + click on Windows.
      return;
    }
    if (e.button === 1) {
      // e.button is '1' when mouse middle button is clicked.
      return;
    }

    if (Boolean(this.props.userUuid) && onProductClick) {
      // 'preventDefault' is to avoid full page reload for SPA transitions.
      e.preventDefault();
      onProductClick();
    }
  };

  handleResize = (isOpen: boolean) => {
    this.props.onResize({ isOpen });
  };

  getThemeMode = () => {
    let themeMode = light;
    if (this.props.hasSettingsNavTheme) {
      themeMode = settings;
    } else if (this.props.hasSiteSettingsNavTheme) {
      themeMode = siteSettings;
    }

    return themeMode;
  };

  // @ts-ignore TODO: fix noImplicitAny error here
  handleRefs = ({ expandCollapseAffordance }, isCollapsed: boolean) => {
    // There is no other way to pass expand/collapse button element a unique
    // selector for the E2E tests.
    if (expandCollapseAffordance.current) {
      const element = expandCollapseAffordance.current as HTMLElement;
      element.setAttribute('data-qa-id', 'expand-collapse-button');
      element.setAttribute(
        'data-qa-open-state',
        isCollapsed ? OPEN_STATE.COLLAPSED : OPEN_STATE.EXPANDED
      );
    }
  };

  getDefaultIsCollapsedState = () => {
    if (this.props.isMobileHeaderActive) {
      return false;
    } else {
      return !this.props.defaultNavigationOpen;
    }
  };

  // When rendering outside of the mobile navigation, the "global" navigation is rendered either
  // horizontally (persistent horizontal white bar).
  // When rendering *inside* the mobile navigation, the "global" navigation is hidden until the
  // menu icon is clicked. Once the menu is clicked the global nav shows as a vertical white bar
  shouldRenderGlobalNavHorizontal = () =>
    !this.props.isMobileHeaderActive &&
    !this.props.isBeingRenderedInsideMobileNav;

  shouldRenderGlobalNavMobile = () =>
    this.props.isMobileHeaderActive &&
    this.props.isBeingRenderedInsideMobileNav;

  shouldRenderGlobalNavVertical = () =>
    this.props.isBeingRenderedInsideMobileNav;

  // The "container" navigation is the route-specific navigation i.e. the "gray" nav area.
  // It is always rendered inside the mobile nav.
  // Outside of the mobile nav, it is always rendered unless the horizontal nav feature is
  // enabled AND the user is on a /dashboard, /, /account/settings/ and /account/workspaces/ routes.
  shouldRenderContainerNav = () => {
    if (this.props.isMobileHeaderActive) {
      return false;
    } else if (this.props.navigationType === 'product') {
      return false;
    } else {
      if (this.props.isAccountSettingsIndexEnabled) {
        return (
          window.location.pathname !== '/' &&
          window.location.pathname.indexOf('/dashboard/') !== 0 &&
          window.location.pathname.indexOf('/account/workspaces/') !== 0
        );
      }
      return (
        window.location.pathname !== '/' &&
        window.location.pathname.indexOf('/dashboard/') !== 0 &&
        window.location.pathname.indexOf('/account/settings/') !== 0 &&
        window.location.pathname.indexOf('/account/workspaces/') !== 0
      );
    }
  };

  hideGlobalSideNav = () =>
    this.isGlobalNavHidden() &&
    window.location.pathname.indexOf('/dashboard/') === 0;

  render() {
    const {
      intl,
      isMobileHeaderActive,
      navigationType,
      containerNavigationComponent,
      topOffset,
    } = this.props;

    const containerNavigation =
      navigationType === 'container' ? containerNavigationComponent : null;

    const shouldRenderGlobalNavHorizontal =
      this.shouldRenderGlobalNavHorizontal();
    const shouldRenderGlobalNavVertical = this.shouldRenderGlobalNavVertical();
    const shouldRenderContainerNav = this.shouldRenderContainerNav();

    return (
      <ThemeProvider
        // @ts-ignore TODO: fix noImplicitAny error here
        theme={theme => ({
          ...theme,
          mode: this.getThemeMode(),
        })}
      >
        <NavigationProvider
          initialUIController={buildUiController(
            isMobileHeaderActive,
            this.getDefaultIsCollapsedState()
          )}
        >
          <UIControllerSubscriber>
            {(uiController: UIControllerInterface) => (
              <LayoutManager
                // experimental_horizontalGlobalNav hides all of the left side nav's "global"
                // blue section which is what we want when in mobile mode.
                experimental_horizontalGlobalNav={
                  shouldRenderGlobalNavHorizontal || !shouldRenderContainerNav
                }
                showContextualNavigation={shouldRenderContainerNav}
                topOffset={topOffset}
                // "productNavigation" can't be null. So just rendering an empty "div"
                // if the navigationType is not "product".
                productNavigation={() => <div />}
                containerNavigation={
                  shouldRenderContainerNav && containerNavigation
                }
                collapseToggleTooltipContent={() => ({
                  text: intl.formatMessage(messages.resizerButtonLabel),
                  char: COLLAPSE_TOGGLE_SHORTCUT,
                })}
                onExpandStart={() => {
                  this.handleResize(true);
                }}
                onCollapseStart={() => {
                  this.handleResize(false);
                }}
                experimental_flyoutOnHover
                experimental_alternateFlyoutBehaviour
                experimental_fullWidthFlyout
                shouldHideGlobalNavShadow={shouldRenderGlobalNavVertical}
                globalNavigation={this.renderGlobalNavigation}
                // @ts-ignore TODO: fix noImplicitAny error here
                getRefs={refs =>
                  this.handleRefs(refs, uiController.state.isCollapsed)
                }
              >
                {this.props.children}
              </LayoutManager>
            )}
          </UIControllerSubscriber>
        </NavigationProvider>
      </ThemeProvider>
    );
  }
}

export default injectIntl(NavigationNext);
